import Layout from 'antd/es/layout';
import theme from 'antd/es/theme';
import { type ReactNode } from 'react';
import styled from 'styled-components';

import SideBar from './SideBar';

const Content = styled(Layout.Content)<{ $backgroundColor: string }>`
  display: flex;
  border-radius: 8px;
  margin-top: 8px;
  margin-left: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

const Sider = styled(Layout.Sider)<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  background: ${(props) => props.$backgroundColor};
  margin-top: 8;
  margin-right: 6;
  padding: 6px 6px 0px 6px;
  border-radius: ${(props) => props.$borderRadius};
`;

const TeamLayout = ({ children }: { children: ReactNode }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <>
      <Sider
        width={250}
        $backgroundColor={colorBgContainer}
        $borderRadius={borderRadiusLG}
      >
        <SideBar />
      </Sider>
      <Layout>
        <Content $backgroundColor={colorBgContainer}>{children}</Content>
      </Layout>
    </>
  );
};

export default TeamLayout;
