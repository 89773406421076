import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import theme from 'antd/es/theme';
import { useMemo } from 'react';
import styled from 'styled-components';

import ThemeSwitch from './ThemeSwitch';

const DropdownContainerInner = styled.div<{
  $background: string;
  $borderRadius: number;
}>`
  background: ${({ $background }) => $background};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding: 8px;
`;

const DropdownContainer = ({ children }: { children: React.ReactNode }) => {
  const themeToken = theme.useToken();

  return (
    <DropdownContainerInner
      $background={themeToken.token.colorBgLayout}
      $borderRadius={themeToken.token.borderRadiusLG}
    >
      {children}
    </DropdownContainerInner>
  );
};

const Settings = () => {
  const queryClient = useQueryClient();

  const settings = useMemo(() => {
    return [
      {
        id: 'account-settings',
        title: 'Account Settings',
      },
      {
        id: 'logout',
        title: 'Logout',
        onClick: async () => {
          localStorage.removeItem('sessionToken');
          await queryClient.clear();
          window.location.href = '/';
        },
      },
      {
        id: 'theme-switch',
        disabled: true,
        title: (
          <div
            style={{
              display: 'flex',
              gap: '1rem',
            }}
          >
            Switch Theme <ThemeSwitch />
          </div>
        ),
      },
    ];
  }, [queryClient]);

  const dropdownRender: Parameters<typeof Dropdown>[0]['dropdownRender'] = (
    menu,
  ) => {
    return <DropdownContainer>{menu}</DropdownContainer>;
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: settings.map((n) => {
          return { key: n.id, label: n.title, onClick: n.onClick };
        }),
      }}
      dropdownRender={dropdownRender}
    >
      <Button shape="circle">
        <SettingOutlined />
      </Button>
    </Dropdown>
  );
};

export default Settings;
