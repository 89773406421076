import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ConfigProvider, { type ThemeConfig } from 'antd/es/config-provider';
import Spin from 'antd/es/spin';
import theme from 'antd/es/theme';
import { Suspense, createContext, useMemo } from 'react';
import { lazy } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import tinyColor from 'tinycolor2';

import Root from './pages';

import { ContentContainer } from '@components/ContentContainer';
import ContentNotFound from '@components/ContentNotFound';
import UnauthenticatedLayout from '@components/UnauthenticatedLayout';
import RootRedirect from '@pages/RootRedirect';
import TeamsRoot from '@pages/team';
import TeamRoot from '@pages/team/:teamId';
import TeamRootRedirect from '@pages/team/TeamRootRedirect';
import { useLocalStorage } from '@utils/theme';

const RegisterVerification = lazy(
  () => import('@pages/auth/register/verification'),
);
const ClientDetails = lazy(
  () => import('@pages/team/:teamId/clients/:clientId'),
);
const Clients = lazy(() => import('@pages/team/:teamId/clients'));
const CreateClient = lazy(() => import('@pages/team/:teamId/clients/create'));
const FormBuilder = lazy(() => import('@pages/team/:teamId/forms/FormBuilder'));
const Forms = lazy(() => import('@pages/team/:teamId/forms'));
const Home = lazy(() => import('@pages/Home'));
const Login = lazy(() => import('@pages/auth/login'));
const Matters = lazy(() => import('@pages/team/:teamId/matters'));
const Members = lazy(() => import('@pages/team/:teamId/members'));
const Register = lazy(() => import('@pages/auth/register'));
const RegisterPending = lazy(() => import('@pages/auth/register/pending'));
const ResetPassword = lazy(() => import('@pages/auth/reset-password'));
const TeamCreate = lazy(() => import('@pages/team/TeamCreate'));
const UnauthenticatedRoot = lazy(() => import('@pages/UnauthenticatedRoot'));
const Workflows = lazy(() => import('@pages/team/:teamId/workflows'));
const Integrations = lazy(() => import('@pages/team/:teamId/integrations'));
const Documents = lazy(() => import('@pages/team/:teamId/documents'));

const NotFoundPage = () => {
  return (
    <UnauthenticatedLayout>
      <ContentContainer>
        <ContentNotFound />
      </ContentContainer>
    </UnauthenticatedLayout>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

export type ThemeContextValues = 'dark' | 'light';

export const ThemeContext = createContext<{
  value: ThemeContextValues;
  setValue: (value: ThemeContextValues) => void;
}>({
  value: 'dark',
  setValue: () => {},
});

const Fallback = () => (
  <ContentContainer>
    <Spin />
  </ContentContainer>
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: '',
        element: <RootRedirect />,
      },
      {
        path: '/auth',
        element: (
          <Suspense>
            <UnauthenticatedRoot />
          </Suspense>
        ),
        children: [
          {
            path: 'login',
            element: (
              <Suspense fallback={<Fallback />}>
                <Login />
              </Suspense>
            ),
          },
          {
            path: 'reset-password',
            element: (
              <Suspense fallback={<Fallback />}>
                <ResetPassword />
              </Suspense>
            ),
          },
          {
            path: 'register',
            element: (
              <Suspense fallback={<Fallback />}>
                <Register />
              </Suspense>
            ),
          },
          {
            path: 'register/verification',
            element: (
              <Suspense fallback={<Fallback />}>
                <RegisterVerification />
              </Suspense>
            ),
          },
          {
            path: 'register/pending',
            element: (
              <Suspense fallback={<Fallback />}>
                <RegisterPending />
              </Suspense>
            ),
          },
          {
            path: '*',
            element: (
              <Suspense fallback={<Fallback />}>
                <Navigate to="/auth/login" />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: 'team',
        element: <TeamsRoot />,
        children: [
          {
            path: '',
            element: <TeamRootRedirect />,
          },
          {
            path: 'create',
            element: (
              <Suspense fallback={<Fallback />}>
                <TeamCreate />
              </Suspense>
            ),
          },
          {
            path: ':teamId',
            element: <TeamRoot />,
            children: [
              {
                path: '',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Home />
                  </Suspense>
                ),
              },
              {
                path: 'integrations',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Integrations />
                  </Suspense>
                ),
              },
              {
                path: 'members',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Members />
                  </Suspense>
                ),
              },
              {
                path: 'documents',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Documents />
                      </Suspense>
                    ),
                  },
                  {
                    path: '*',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <NotFoundPage />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'clients',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Clients />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <CreateClient />
                      </Suspense>
                    ),
                  },
                  {
                    path: ':clientId',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <ClientDetails />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'forms',
                children: [
                  {
                    path: '',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <Forms />
                      </Suspense>
                    ),
                  },
                  {
                    path: 'builder',
                    element: (
                      <Suspense fallback={<Fallback />}>
                        <FormBuilder />
                      </Suspense>
                    ),
                  },
                ],
              },
              {
                path: 'matters',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Matters />
                  </Suspense>
                ),
              },
              {
                path: 'workflows',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <Workflows />
                  </Suspense>
                ),
              },
              {
                path: '*',
                element: (
                  <Suspense fallback={<Fallback />}>
                    <NotFoundPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: (
      <Suspense fallback={<Fallback />}>
        <NotFoundPage />
      </Suspense>
    ),
  },
]);

export const App = () => {
  const [themeContextValue, setThemeContextValue] =
    useLocalStorage<ThemeContextValues>('theme', 'dark');

  const themeConfiguration: ThemeConfig = useMemo(() => {
    // Brand Color, use this for primary components and accents
    const primaryColor = '#625cdd';

    // Secondary Color, we use this for non-primary buttons, selected items etc...
    const secondaryColor =
      themeContextValue === 'dark'
        ? '#3d3d3d'
        : tinyColor(primaryColor).lighten(30).toString();
    const backgroundColor =
      themeContextValue === 'dark' ? '#121212' : '#f0f2f5';
    const textColor = themeContextValue === 'dark' ? '#f0f2f5' : '#121212';
    const colorFillSecondary =
      themeContextValue === 'dark' ? '#817de4' : '#c0bef1';

    // Derived Colours
    const surfaceColor =
      themeContextValue === 'dark'
        ? tinyColor(backgroundColor).lighten(5).toString()
        : tinyColor(backgroundColor).darken(2).toString();
    const borderColor = tinyColor(primaryColor).darken(5).toString();
    const lightSurfaceColor = tinyColor(surfaceColor).lighten(3).toString();
    return {
      algorithm:
        themeContextValue === 'dark'
          ? theme.darkAlgorithm
          : theme.defaultAlgorithm,
      components: {
        Layout: {
          headerBg: backgroundColor,
          siderBg: backgroundColor,
          margin: 0,
          padding: 0,
        },
        Menu: {
          itemSelectedBg: secondaryColor,
          itemSelectedColor: textColor,
        },
        Tag: {
          defaultBg: surfaceColor,
        },
        Card: {
          colorBgContainer: lightSurfaceColor,
        },
      },
      token: {
        colorPrimary: primaryColor,
        colorBorderBg: backgroundColor,
        colorBgBase: backgroundColor,
        colorBgContainer: surfaceColor,
        colorBgLayout: backgroundColor,
        colorBorder: borderColor,
        colorTextBase: textColor,
        colorFillSecondary,
      },
    } as ThemeConfig;
  }, [themeContextValue]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider
        value={{
          value: themeContextValue,
          setValue: setThemeContextValue,
        }}
      >
        <ConfigProvider theme={themeConfiguration}>
          {<RouterProvider router={router} />}
        </ConfigProvider>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
};
