import { z } from 'zod';

export const TeamSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type TeamSummary = z.infer<typeof TeamSummarySchema>;

export const TeamDetailsSchema = TeamSummarySchema.extend({
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type TeamDetails = z.infer<typeof TeamDetailsSchema>;

export const CreateTeamRequestSchema = z.object({
  name: z.string(),
});

export type CreateTeamRequest = z.infer<typeof CreateTeamRequestSchema>;

export const ListTeamsRequestSchema = z.object({
  name: z.string().optional(),
  teamIds: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.string()))
    .optional(),
});

export type ListTeamsRequest = z.infer<typeof ListTeamsRequestSchema>;

export const ListTeamsResponseSchema = z.array(TeamSummarySchema);

export type ListTeamsResponse = z.infer<typeof ListTeamsResponseSchema>;
