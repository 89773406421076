import { z } from 'zod';

/**
 * User summary.
 * Contains minimal information, meant for lookup queries.
 */
export const UserSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type UserSummary = z.infer<typeof UserSummarySchema>;

/**
 * User details.
 * Contains extended information about a user.
 */
export const UserDetailSchema = UserSummarySchema.extend({
  emails: z.string().array(),
  createdAt: z.coerce.date(),
});

export type UserDetail = z.infer<typeof UserDetailSchema>;

/**
 * List users query schema
 * Contains query parameters for listing users.
 */
export const ListUsersQuerySchema = z.object({
  userIds: z.string().array().optional(),
  name: z.string().optional(),
  emails: z.string().array().optional(),
  teamIds: z.string().array().optional(),
});

export type ListUsersQuery = z.infer<typeof ListUsersQuerySchema>;

/**
 * List users response schema
 * Contains response schema for listing users.
 */
export const ListUsersResponseSchema = z.array(UserSummarySchema);

export type ListUsersResponse = z.infer<typeof ListUsersResponseSchema>;

/**
 * Get user by ID path schema
 */
export const GetUserRequestParamsSchema = z.object({
  userId: z.string(),
});

export type GetUserRequestParams = z.infer<typeof GetUserRequestParamsSchema>;

/**
 * Get user by ID response schema
 */
export const GetUserResponseSchema = UserDetailSchema;

export type GetUserResponse = z.infer<typeof GetUserResponseSchema>;
