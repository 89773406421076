import { z } from 'zod';

export const TeamUserSchema = z.object({
  teamId: z.string(),
  userId: z.string(),
  createdAt: z.coerce.date(),
});

export const ListTeamUsersRequestSchema = z.object({
  teamIds: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.string()))
    .optional(),
  userIds: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.string()))
    .optional(),
});

export type ListTeamUsersRequest = z.infer<typeof ListTeamUsersRequestSchema>;

export const ListTeamUsersResponseSchema = z.array(TeamUserSchema);

export type ListTeamUsersResponse = z.infer<typeof ListTeamUsersResponseSchema>;

export const CreateTeamUserRequestSchema = z.object({
  teamId: z.string(),
  userId: z.string(),
});

export const CreateTeamUserResponseSchema = TeamUserSchema;
