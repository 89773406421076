import Spin from 'antd/es/spin';
import { Outlet } from 'react-router-dom';

import AuthenticatedLayout from '@components/AuthenticatedLayout';
import UnauthenticatedLayout from '@components/UnauthenticatedLayout';
import { useAuthWhoami } from '@queries/auth';

const Root = () => {
  const authWhoamiQuery = useAuthWhoami();
  const authUserId = authWhoamiQuery.data?.userId;

  if (authWhoamiQuery.isLoading) {
    return <Spin />;
  }

  if (!authUserId) {
    return (
      <UnauthenticatedLayout>
        <Outlet />
      </UnauthenticatedLayout>
    );
  }

  return (
    <AuthenticatedLayout>
      <Outlet />
    </AuthenticatedLayout>
  );
};

export default Root;
