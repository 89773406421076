import { z } from 'zod';

export const CreateSearchRequestBodySchema = z.object({
  query: z.string(),
  features: z
    .object({
      metadataSearch: z.boolean().optional().default(true),
      semanticSearch: z.boolean().optional().default(true),
      keywordSearch: z.boolean().optional().default(true),
    })
    .optional(),
  filters: z
    .object({
      type: z.string().optional(),
      embeddingIds: z.array(z.string()).optional(),
      teamIds: z.array(z.string()).optional(),
      accessibleByUserIds: z.array(z.string()).optional(),
    })
    .optional()
    .default({}),
});

export type CreateSearchRequestBody = z.infer<
  typeof CreateSearchRequestBodySchema
>;

export const SearchResultSchema = z.object({
  id: z.string(),
  type: z.string(),
  score: z.number(),
});

export type SearchResult = z.infer<typeof SearchResultSchema>;

export const CreateSearchRequestResponseBodySchema =
  z.array(SearchResultSchema);

export type CreateSearchRequestResponseBody = z.infer<
  typeof CreateSearchRequestResponseBodySchema
>;
