import axios from 'axios';

export const apiClient = axios.create({
  ...axios.defaults,
  baseURL:
    import.meta.env.MODE === 'development'
      ? 'http://localhost:3001'
      : 'https://api.moderately.ai',
  timeout: 5000,
  withCredentials: true,
  paramsSerializer: (params) => {
    // Convert arrays to comma-separated strings
    return Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          const filteredValues = value.filter((v) => v !== undefined);
          return `${key}=${filteredValues.join(',')}`;
        } else if (value === undefined) {
          return '';
        }
        return `${key}=${value}`;
      })
      .join('&');
  },
});

apiClient.interceptors.request.use(
  function (config) {
    const sessionToken = localStorage.getItem('sessionToken');
    if (sessionToken) {
      config.headers.Authorization = `Bearer ${sessionToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
