import Spin from 'antd/es/spin';
import { Navigate } from 'react-router-dom';

import ContentError from '@components/ContentError';
import { useAuthWhoami } from '@queries/auth';
import { useFetchTeamUsers } from '@queries/teams';

const TeamRootRedirect = () => {
  const authWhoamiQuery = useAuthWhoami();
  const userId = authWhoamiQuery.data?.userId;

  const fetchTeamQuery = useFetchTeamUsers({
    userIds: userId ? [userId] : undefined,
  });
  const teamId = fetchTeamQuery.data?.[0]?.teamId;

  if (fetchTeamQuery.isLoading || authWhoamiQuery.isLoading) {
    return <Spin />;
  }

  if (fetchTeamQuery.isError || authWhoamiQuery.isError) {
    return <ContentError />;
  }

  if (!teamId) {
    return <Navigate to="/team/create" replace />;
  }

  return <Navigate to={`/team/${teamId}`} replace />;
};

export default TeamRootRedirect;
