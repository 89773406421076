import InboxOutlined from '@ant-design/icons/InboxOutlined';
import Badge from 'antd/es/badge';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import theme from 'antd/es/theme';
import { useState } from 'react';
import styled from 'styled-components';

import { type Notification } from '@mai/types';

const DropdownContainerInner = styled.div<{
  $background: string;
  $borderRadius: number;
}>`
  background: ${({ $background }) => $background};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding: 8px;
`;

const DropdownContainer = ({ children }: { children: React.ReactNode }) => {
  const themeToken = theme.useToken();

  return (
    <DropdownContainerInner
      $background={themeToken.token.colorBgLayout}
      $borderRadius={themeToken.token.borderRadiusLG}
    >
      {children}
    </DropdownContainerInner>
  );
};

/**
 * Icon button that displays a badge with the number of notifications.
 * Clicking the button opens a dropdown with the notifications along with basic actions like marking all as read.
 */
const Notifications = () => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const [notifications] = useState<Notification[]>([
    {
      id: '1',
      title: '3 New Messages',
    },
  ]);

  const dropdownRender: Parameters<typeof Dropdown>[0]['dropdownRender'] = (
    menu,
  ) => {
    if (notifications.length === 0) {
      return (
        <DropdownContainer>
          <p>No notifications</p>
        </DropdownContainer>
      );
    }

    return <DropdownContainer>{menu}</DropdownContainer>;
  };

  return (
    <Badge count={notifications.length} color={colorPrimary}>
      <Dropdown
        trigger={['click']}
        menu={{
          items: notifications.map((n) => {
            return { key: n.id, label: n.title };
          }),
        }}
        dropdownRender={dropdownRender}
      >
        <Button shape="circle">
          <InboxOutlined />
        </Button>
      </Dropdown>
    </Badge>
  );
};

export default Notifications;
