import { z } from 'zod';

// Summary of a client
// Contains only basic information that is cheap to fetch
export const ClientSummarySchema = z.object({
  id: z.string(),
  name: z.string(),
  status: z.enum(['active', 'inactive']),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
});

export type ClientSummary = z.infer<typeof ClientSummarySchema>;

// Schema for a client
// Contains extended information that may be more expensive to fetch
export const ClientDetailSchema = ClientSummarySchema.extend({});

export type ClientDetail = z.infer<typeof ClientDetailSchema>;

// Schema for getting a client by id
export const GetClientRequestParamsSchema = z.object({
  clientId: z.string(),
});

export type GetClientRequestParams = z.infer<
  typeof GetClientRequestParamsSchema
>;

// Schema for creation of a client
export const CreateClientBodySchema = z.object({
  name: z.string(),
  teamId: z.string(),
});

export type CreateClientBody = z.infer<typeof CreateClientBodySchema>;

// Schema for updating a client
export const UpdateClientBodySchema = z.object({
  name: z.string().optional(),
});

export type UpdateClientBody = z.infer<typeof UpdateClientBodySchema>;

// Schema for a client, query parameters
export const ListClientRequestQuerySchema = z.object({
  ids: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.string()))
    .optional(),
  name: z.string().optional(),
  statuses: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.enum(['active', 'inactive'])))
    .optional(),
  teamIds: z
    .string()
    .transform((v) => v.split(','))
    .pipe(z.array(z.string()))
    .optional(),
});

export type ListClientRequestQuery = z.infer<
  typeof ListClientRequestQuerySchema
>;

// Response schema for listing clients
export const ListClientResponseSchema = z.array(ClientSummarySchema);

export type ListClientResponse = z.infer<typeof ListClientResponseSchema>;
