import ArrowDownOutlined from '@ant-design/icons/ArrowDownOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import { useQuery } from '@tanstack/react-query';
import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Input from 'antd/es/input';
import Skeleton from 'antd/es/skeleton';
import theme from 'antd/es/theme';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { type ListTeamsRequest, ListTeamsResponseSchema } from '@mai/types';

import { apiClient } from '../queries';

import { useAuthWhoami } from '@queries/auth';
import { useFetchTeamUsers } from '@queries/teams';

const useFetchTeams = (params: ListTeamsRequest) => {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: async () => {
      const teamsQuery = await apiClient.get('teams', {
        params,
      });
      return ListTeamsResponseSchema.parse(teamsQuery.data);
    },
    enabled: Boolean(params.teamIds?.length),
  });
};

const CompanySelector = () => {
  const [teamSearch, setTeamSearch] = useState('');

  const {
    token: { borderRadiusLG },
  } = theme.useToken();

  const { teamId } = useParams();

  const whoamiQuery = useAuthWhoami();
  const sessionUserId = whoamiQuery.data?.userId;

  const teamUsersQuery = useFetchTeamUsers({
    userIds: sessionUserId ? [sessionUserId] : undefined,
  });
  const teamIds = teamUsersQuery.data?.map((teamUser) => teamUser.teamId) ?? [];

  const teamsQuery = useFetchTeams({ teamIds });

  if (whoamiQuery.isLoading || teamsQuery.isLoading) {
    return (
      <Skeleton.Button
        active
        style={{
          width: '100%',
        }}
      />
    );
  }

  const teams = teamsQuery.data ?? [];
  const selectedTeam = teams.find((team) => team.id === teamId);

  if (!teams.length) {
    return <Button type="primary">No teams</Button>;
  }

  const filteredTeams = teams
    .filter((team) =>
      team.name.toLowerCase().includes(teamSearch.toLowerCase()),
    )
    .splice(0, 5);

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: 'search',
            type: 'group',
            label: (
              <Input.Search
                allowClear
                placeholder="Search teams"
                loading={teamsQuery.isLoading}
                value={teamSearch}
                onChange={(event) => {
                  setTeamSearch(event.target.value);
                }}
              />
            ),
          },
          ...filteredTeams.map((team) => ({
            key: team.id,
            label: <Link to={`/team/${team.id}`}>{team.name}</Link>,
          })),
          {
            type: 'divider',
          },
          {
            type: 'item',
            key: 'manage-teams',
            label: (
              <Link
                to="/team/create"
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <SettingOutlined /> Manage Teams
              </Link>
            ),
          },
          {
            key: 'create-team',
            label: (
              <Link
                to="/team/create"
                style={{
                  display: 'flex',
                  gap: '8px',
                }}
              >
                <PlusCircleOutlined /> Create Team
              </Link>
            ),
          },
        ],
      }}
    >
      <Button
        type="primary"
        style={{
          borderRadius: borderRadiusLG,
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
        }}
      >
        {selectedTeam?.name}
        <ArrowDownOutlined />
      </Button>
    </Dropdown>
  );
};

export default CompanySelector;
