import { useQuery } from '@tanstack/react-query';

import {
  type ListTeamUsersResponse,
  ListTeamUsersResponseSchema,
} from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useFetchTeamUsers = (params: {
  userIds?: string[];
  teamIds?: string[];
}) => {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: async () => {
      const { data } = await apiClient.get<ListTeamUsersResponse>(
        `/team-users`,
        {
          params: {
            userIds: params.userIds?.join(',') || undefined,
            teamIds: params.teamIds?.join(',') || undefined,
          },
        },
      );
      const validatedData = ListTeamUsersResponseSchema.safeParse(data);
      if (validatedData.success) {
        return validatedData.data;
      } else {
        logger.error({
          message: 'Failed to fetch team users',
          error: validatedData.error,
        });
        return data;
      }
    },
    enabled: Boolean(params.userIds?.length),
  });
};
