import { Navigate, Outlet } from 'react-router-dom';

import { ContentContainer } from '@components/ContentContainer';
import ContentLoading from '@components/ContentLoading';
import { useAuthWhoami } from '@queries/auth';

const TeamsRoot = () => {
  const authWhoamiQuery = useAuthWhoami();

  if (authWhoamiQuery.isLoading) {
    return (
      <ContentContainer>
        <ContentLoading />
      </ContentContainer>
    );
  }

  if (authWhoamiQuery.isError || !authWhoamiQuery.data?.userId) {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
};

export default TeamsRoot;
