import { z } from 'zod';

export const DocumentSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  contentHash: z.string(),
  fileType: z.string(),
  fileName: z.string(),
  createdAt: z.coerce.date(),
  downloadUrl: z.string(),
  uploadUrl: z.string(),
});

export type DocumentSummary = z.infer<typeof DocumentSummarySchema>;

export const DocumentLinkSchema = z.object({
  type: z.enum(['client', 'team']),
  id: z.string(),
});

export const DocumentDetailSchema = DocumentSummarySchema.extend({
  links: z.array(DocumentLinkSchema),
});

export type DocumentDetail = z.infer<typeof DocumentDetailSchema>;

export const ListDocumentsQuerySchema = z.object({
  documentIds: z.string().array().optional(),
  teamIds: z.string().array().optional(),
  clientIds: z.string().array().optional(),
  title: z.string().optional(),
});

export type ListDocumentsQuery = z.infer<typeof ListDocumentsQuerySchema>;

export const ListDocumentsResponseSchema = z.array(DocumentSummarySchema);

export type ListDocumentsResponse = z.infer<typeof ListDocumentsResponseSchema>;

export const CreateDocumentRequestSchema = z.object({
  title: z.string(),
  description: z.string(),
  contentHash: z.string(),
  fileType: z.string(),
  fileName: z.string(),
  file: z.any(),
  links: z.array(DocumentLinkSchema),
});

export type CreateDocumentRequest = z.infer<typeof CreateDocumentRequestSchema>;
