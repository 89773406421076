import { useQuery } from '@tanstack/react-query';

import { type WhoamiResponse, WhoamiResponseSchema } from '@mai/types';

import { apiClient } from '.';

import { logger } from '@utils/logger';

export const useAuthWhoami = () => {
  return useQuery({
    queryKey: ['auth'],
    queryFn: async () => {
      try {
        const { data } = await apiClient.get('/auth/whoami');
        const validatedData = WhoamiResponseSchema.safeParse(data);
        if (validatedData.success) {
          return validatedData.data;
        } else {
          logger.error({
            message: 'Failed to fetch whoami',
            error: validatedData.error,
          });
          return data as WhoamiResponse;
        }
      } catch (error) {
        logger.error({
          message: 'Failed to fetch whoami',
          error,
        });
        return {
          userId: null,
        } as WhoamiResponse;
      }
    },
    retry: false,
  });
};
