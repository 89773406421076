import QuestionOutlined from '@ant-design/icons/QuestionOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Layout from 'antd/es/layout';
import Modal from 'antd/es/modal';
import theme from 'antd/es/theme';
import Typography from 'antd/es/typography';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ThemeContext } from '../App';
import ActiveUsers from './ActiveUsers';
import NotificationButton from './Notifications';
import Settings from './Settings';

import LogoDarkMode from '@assets/logo-dark.png';
import LogoLightMode from '@assets/logo-light.png';

const Header = styled(Layout.Header)<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: 8px;
  background: ${(props) => props.$backgroundColor};
  border-radius: ${(props) => props.$borderRadius};
`;

const TopBar = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const themeContext = useContext(ThemeContext);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  return (
    <Header $backgroundColor={colorBgContainer} $borderRadius={borderRadiusLG}>
      <Modal
        open={isSearchOpen}
        onCancel={() => setIsSearchOpen(false)}
        closable={false}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <Input.Search
          placeholder="Search..."
          enterButton
          size="large"
          onSearch={() => {
            // TODO: Implement search
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '16px',
          }}
        >
          <Typography.Text>
            {'No recent searches. Start typing to search for anything!'}
          </Typography.Text>
        </div>
      </Modal>
      <Link
        to="/"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          src={themeContext.value === 'dark' ? LogoDarkMode : LogoLightMode}
          style={{
            marginLeft: '16px',
            width: '50px',
          }}
        />
      </Link>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
        }}
      >
        <Button
          type="primary"
          icon={<SearchOutlined />}
          iconPosition="end"
          onClick={() => {
            setIsSearchOpen(true);
          }}
        >
          {'Search Your Organization'}
        </Button>
        <ActiveUsers />
        <NotificationButton />
        <Button shape="circle">
          <QuestionOutlined />
        </Button>
        <Settings />
      </div>
    </Header>
  );
};

export default TopBar;
