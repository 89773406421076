import Skeleton from 'antd/es/skeleton';
import Spin from 'antd/es/spin';
import styled from 'styled-components';

import { ContentContainer } from './ContentContainer';
import ContentHeader from './ContentHeader';

const BreadcrumbSkeleton = styled(Skeleton)`
  width: 100px;
`;

const ButtonSkeleton = styled(Skeleton.Button)`
  width: 100px;
`;

const TitleSkeleton = styled(Skeleton)`
  width: 200px;
`;

const ContentLoading = () => {
  return (
    <ContentContainer>
      <ContentHeader
        breadcrumbs={[
          { label: <BreadcrumbSkeleton title paragraph={false} active /> },
          { label: <BreadcrumbSkeleton title paragraph={false} active /> },
        ]}
        title={<TitleSkeleton title paragraph={false} active />}
        cta={<ButtonSkeleton active />}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Spin size="large" tip={'Loading...'}>
          <div
            style={{
              padding: 50,
              borderRadius: 4,
            }}
          />
        </Spin>
      </div>
    </ContentContainer>
  );
};

export default ContentLoading;
