import UserOutlined from '@ant-design/icons/UserOutlined';
import Avatar from 'antd/es/avatar';
import Badge from 'antd/es/badge';
import theme from 'antd/es/theme';
import styled from 'styled-components';

const Container = styled.div<{
  $backgroundColor: string;
  $borderColor: string;
}>`
  display: flex;
  background-color: ${(props) => props.$backgroundColor};
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${(props) => props.$borderColor};
  padding: 0 8px 0 8px;
  height: 2rem;
  gap: 0.5rem;
`;

const ActiveUsers = () => {
  const {
    token: { colorPrimary, colorBgBase, colorBorder },
  } = theme.useToken();

  return (
    <Container $backgroundColor={colorBgBase} $borderColor={colorBorder}>
      <Badge status="success" text="Online" />
      <Avatar.Group
        shape="square"
        size="small"
        max={{
          count: 1,
          style: { color: colorPrimary, backgroundColor: '#d3d3d3' },
        }}
      >
        <Avatar style={{ backgroundColor: colorPrimary }}>K</Avatar>
        <Avatar
          style={{ backgroundColor: colorPrimary }}
          icon={<UserOutlined />}
        />
      </Avatar.Group>
    </Container>
  );
};

export default ActiveUsers;
