import { ContentContainer } from './ContentContainer';

import NotFound from '@components/NotFound';

const ContentNotFound = () => {
  return (
    <ContentContainer>
      <NotFound />
    </ContentContainer>
  );
};

export default ContentNotFound;
