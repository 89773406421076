import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: min-content;
  max-height: 100%;
  width: 100%;
  padding: 1rem;
  overflow: auto;
`;
