import BookOutlined from '@ant-design/icons/BookOutlined';
import FormOutlined from '@ant-design/icons/FormOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import MergeOutlined from '@ant-design/icons/MergeOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import UsergroupAddOutlined from '@ant-design/icons/UsergroupAddOutlined';
import Menu from 'antd/es/menu';
import { type MenuProps } from 'antd/es/menu';
import theme from 'antd/es/theme';
import { useMemo } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CompanySelector from '@components/CompanySelector';

const Container = styled.div<{
  $backgroundColor: string;
  $borderRadius: number;
}>`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  border-radius: ${(props) => props.$borderRadius}px;
  background-color: ${(props) => props.$backgroundColor};
`;

const SideBar = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const { teamId } = useParams();

  const sidebarItems: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: '/team/:teamId',
        label: <Link to={`/team/${teamId}`}>Home</Link>,
        icon: <HomeOutlined />,
      },
      {
        key: '/team/:teamId/clients',
        label: <Link to={`/team/${teamId}/clients`}>Clients</Link>,
        icon: <UserOutlined />,
      },
      {
        key: '/team/:teamId/documents',
        label: <Link to={`/team/${teamId}/documents`}>Documents</Link>,
        icon: <BookOutlined />,
      },
      {
        key: '/team/:teamId/forms',
        label: <Link to={`/team/${teamId}/forms`}>Forms</Link>,
        icon: <FormOutlined />,
      },
      {
        key: '/team/:teamId/workflows',
        label: <Link to={`/team/${teamId}/workflows`}>Workflows</Link>,
        icon: <MergeOutlined />,
      },
      {
        key: '/team/:teamId/members',
        label: <Link to={`/team/${teamId}/members`}>Team Members</Link>,
        icon: <UsergroupAddOutlined />,
      },
      {
        key: '/team/:teamId/integrations',
        label: <Link to={`/team/${teamId}/integrations`}>Integrations</Link>,
        icon: <ShopOutlined />,
      },
    ];
  }, [teamId]);

  const sidebarItemKeys: string[] = useMemo(() => {
    return sidebarItems
      .flatMap((item) => {
        const children = item?.type === 'group' ? (item.children ?? []) : [];
        return [item?.key, ...children.map((child) => child?.key)]
          .map((i) => {
            return i?.toString();
          })
          .filter((i) => i);
      })
      .sort((a, b) => (b?.length ?? 0) - (a?.length ?? 0)) as string[];
  }, [sidebarItems]);

  const location = useLocation();

  const selectedKey = useMemo(() => {
    const path = location.pathname;
    const interpolatedKeys = sidebarItemKeys.map((key) => {
      return key.replace(':teamId', teamId!);
    });
    const selectedKey = interpolatedKeys.find((key) => path.startsWith(key));
    return selectedKey?.replace(teamId!, ':teamId');
  }, [location.pathname, sidebarItemKeys, teamId]);

  return (
    <Container
      $backgroundColor={colorBgContainer}
      $borderRadius={borderRadiusLG}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          gap: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: '0.25rem',
            marginRight: '0.25rem',
          }}
        >
          <CompanySelector />
        </div>
        <div
          style={{
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <Menu
            items={sidebarItems}
            selectedKeys={selectedKey ? [selectedKey] : undefined}
            style={{
              borderInlineEnd: 'none',
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default SideBar;
