import Typography from 'antd/es/typography';

import { ContentContainer } from './ContentContainer';

const ContentError = () => {
  return (
    <ContentContainer>
      <Typography.Title>Oops! Something went wrong.</Typography.Title>
      <Typography.Paragraph>
        Try refreshing the page or come back later.
      </Typography.Paragraph>
    </ContentContainer>
  );
};

export default ContentError;
