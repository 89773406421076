import Breadcrumb from 'antd/es/breadcrumb';
import Typography from 'antd/es/typography';
import { type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Subtitle = styled.div`
  margin-top: 8px;
`;

const ContentHeader = ({
  breadcrumbs,
  title,
  subtitle,
  cta,
}: {
  breadcrumbs: { label: ReactNode; to?: string }[];
  title: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  cta?: React.ReactNode;
}) => {
  return (
    <Container>
      <Breadcrumb
        items={breadcrumbs.map(({ label, to }, index) => ({
          key: index,
          title: to ? <Link to={to}>{label}</Link> : label,
        }))}
      />
      <Header>
        {typeof title === 'string' ? (
          <Typography.Title
            level={2}
            style={{
              marginBottom: 0,
              marginTop: 8,
            }}
          >
            {title}
          </Typography.Title>
        ) : (
          title
        )}
        {cta}
      </Header>
      <Subtitle>
        {(subtitle && typeof subtitle === 'string' && (
          <Typography.Text>{subtitle}</Typography.Text>
        )) ||
          (subtitle && subtitle)}
      </Subtitle>
    </Container>
  );
};

export default ContentHeader;
