import Spin from 'antd/es/spin';
import { Navigate } from 'react-router-dom';

import { useAuthWhoami } from '@queries/auth';

const RootRedirect = () => {
  const authWhoamiQuery = useAuthWhoami();
  const authUserId = authWhoamiQuery.data?.userId;

  if (authWhoamiQuery.isLoading) {
    return <Spin />;
  } else if (authWhoamiQuery.isError) {
    return <Spin />;
  } else if (!authUserId) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Navigate to="/team" replace />;
};

export default RootRedirect;
